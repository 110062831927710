<template>
  <BaseFrontdoor keepOut>
    <OutsideContainer>
      <OutsideCard title="REGISTER.FORM.TITLE" :loading="isLoading">
        <div v-if="isValid">
          <CreatePassword v-model="password" @complete="setPassword($event)" />

          <v-row justify="end">
            <v-col cols="auto">
              <BaseButton
                @click="registerUser"
                :loading="isSending"
                :disabled="isSending || !passwordComplete"
              >
                {{ $t("REGISTER.FORM.LABEL_BTN") }}
              </BaseButton>
            </v-col>
          </v-row>
        </div>

        <v-row justify="end" v-else>
          <v-col>
            <BaseText class="body-1">{{ $t("REGISTER.FORM.INVALID") }}</BaseText>
          </v-col>
        </v-row>

        <template #cardFooter>
          <p class="gray800--text">
            {{ $t("REGISTER.FORM.REGISTER_LINK") }}
            <router-link :to="{ name: 'Login' }" class="text-decoration-none">
              {{ $t("REGISTER.FORM.REGISTER_LINK_LABEL") }}
            </router-link>
          </p>
        </template>
      </OutsideCard>
    </OutsideContainer>
  </BaseFrontdoor>
</template>

<script>
import OutsideContainer from "@components/Layout/OutsideContainer.vue";
import OutsideCard from "@components/Layout/OutsideCard.vue";
import CreatePassword from "@components/FormElements/Password/Create";
import { mapActions } from "vuex";

export default {
  name: "Register",

  data() {
    return {
      invitationId: null,
      password: {
        password: null,
        passwordConfirm: null,
      },
      passwordComplete: false,
      isLoading: true,
      isSending: false,
      isValid: false,
    };
  },

  components: {
    CreatePassword,
    OutsideCard,
    OutsideContainer,
  },

  computed: {
    token() {
      return this.$route.query.token;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    setPassword(event) {
      this.passwordComplete = event;
    },

    async showInvitation() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.auth.showUserInvitation({ token: this.token });
        this.invitationId = data.data.id;
        this.isValid = true;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    async registerUser() {
      if (this.passwordComplete) {
        this.isSending = true;

        try {
          await this.$axios.auth.registerUser({
            token: this.token,
            invitationId: this.invitationId,
            passwordConfirm: this.password.passwordConfirm,
            password: this.password.password,
          });

          await this.$router.push({ name: "Login" });

          await this.notify({
            type: "success",
            message: "REGISTER.FORM.SUCCESS",
          });
        } catch (err) {
          console.error(err);
        } finally {
          this.isSending = false;
        }
      }
    },
  },

  created() {
    this.showInvitation();
  },
};
</script>
